import Sidebar from '../partials/Sidebar';
import Header from '../partials/Header';
import React, { useState, useEffect } from "react";
import axios from "axios";
import { API_URL } from "../utils/Utils";
import MaterialTable from "material-table";
import { CircularProgress } from '@material-ui/core';
import { toast } from 'react-toastify';

function ThirdConfigs() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [dataList, setdataList] = useState([]);
  const [loading, setLoading] = useState(false);

  const columns = [
    { title: "ID", field: "id", editable: 'never', minWidth: 200 },
    { field: "attributes.floor", title: "Piso", minWidth: 100 },
    { field: "attributes.floor2da", title: "Piso 2DA", minWidth: 100 },
    { field: "attributes.floorvalue", title: "Valor de piso", minWidth: 100 },
    { field: "attributes.floorvalue2da", title: "Valor de piso 2DA", minWidth: 100 },
    { field: "attributes.name_zone", title: "Nombre de zona", minWidth: 100 },
    { field: "attributes.type_truck", title: "Modelo camion", minWidth: 100 },
    { field: "attributes.vap", title: "Valor de pieza adicional", minWidth: 100 },
    { field: "attributes.vap2da", title: "Valor de pieza adicional 2DA", minWidth: 100 },
    { field: "attributes.updated_at", title: "Fecha de actualizacion", editable: "never", minWidth: 100 }
  ];

  const getThirdConfig = async (withLoading = true) => {
    if (withLoading) 
    setLoading(true);
    try {
      const headers = JSON.parse(localStorage.getItem("headers"));
      const {
        data: { data },
      } = await axios.get(`${API_URL}/third_configs`, {
        headers: { Authorization: headers },
      });
      const truck =  data.map(item => item)
      setdataList(truck);
      if (withLoading) 
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  const apiUpdateThirdConfigs = async (id, body) => {
    const headers = JSON.parse(localStorage.getItem("headers"));
    try{
        await axios.put(`${API_URL}/third_configs/${id}`, body, {headers});
        await getThirdConfig(false);
    }catch(e){
    }
  }

  useEffect(() => {
    getThirdConfig();
  }, []);

  return (
    <div className="flex h-screen overflow-hidden">
    <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
    <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <div className='p-5'>
            <div className='p-2'>
            {!loading ? <MaterialTable
                columns={columns}
                data={dataList}
                title=""
                editable={{
                  onRowUpdate: (newData, oldData) => { 
                    console.log(newData)
                    return apiUpdateThirdConfigs(oldData.id, {third_config: {...newData.attributes}})}
                  }}
                options={{
                    paging:true,
                    pageSize:10,
                    pageSizeOptions:[10, 20],
                }}
            />
            : <CircularProgress/>}
            </div>
        </div>
    </div>
</div>
  );
}

export default ThirdConfigs;