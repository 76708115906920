import Sidebar from '../partials/Sidebar';
import Header from '../partials/Header';
import React, { useState, useEffect } from "react";
import axios from "axios";
import { API_URL } from "../utils/Utils";
import MaterialTable from "material-table";
import { CircularProgress } from '@material-ui/core';
import { toast } from 'react-toastify';

function Trucks() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [dataList, setdataList] = useState([]);
  const [loading, setLoading] = useState(false);

  const columns = [
    { field: "driver_name", title: "Nombre del conductor", minWidth: 200 },
    { field: "patent", title: "Patente", minWidth: 200 },
    { field: "type_truck", title: "Modelo camion", minWidth: 200 },
    { field: "updated_at", title: "Fecha de actualizacion", minWidth: 200 }
  ];

  const getTrucks = async (withLoading = true) => {
    if (withLoading) 
    setLoading(true);
    try {
      const headers = JSON.parse(localStorage.getItem("headers"));
      const {
        data: { data },
      } = await axios.get(`${API_URL}/trucks`, {
        headers: { Authorization: headers },
      });
      const truck =  data.map(item => item.attributes)
      setdataList(truck);
      if (withLoading) 
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getTrucks();
  }, []);

  return (
    <div className="flex h-screen overflow-hidden">
    <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
    <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <div className='p-5'>
            <div className='p-2'>
            {!loading ? <MaterialTable
                columns={columns}
                data={dataList}
                title=""
                options={{
                    paging:true,
                    pageSize:10,
                    pageSizeOptions:[10, 20],
                }}
            />
            : <CircularProgress/>}
            </div>
        </div>
    </div>
</div>
  );
}

export default Trucks;