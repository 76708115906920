import React, { useEffect } from 'react';
import {
  Routes,
  Route,
  useLocation
} from 'react-router-dom';

import './App.scss';

import './charts/ChartjsConfig';

// Import pages
import Dashboard from './pages/Dashboard';
import SignIn from './pages/SignIn';
import Calendar from './pages/Calendar';
import Private from './Private';
import ScheduleDetails from './pages/ScheduleDetails';
import ZonePrices from './pages/ZonePrices';
import Dictionary from './pages/Dictionary';
import Trucks from './pages/Trucks';
import NoTypes from './pages/NoTypes';
import ThirdPayments from './pages/ThirdPayments';
import AllPatent from './pages/AllPatent';
import ThirdConfigs from './pages/ThirdConfigs'; 


function App() {

  const location = useLocation();

  useEffect(() => {
    document.querySelector('html').style.scrollBehavior = 'auto'
    window.scroll({ top: 0 })
    document.querySelector('html').style.scrollBehavior = ''
  }, [location.pathname]); // triggered on route change

  return (
    <>
      <Routes>
        <Route exact path="/" element={<Private Component={Dashboard} />} />
        <Route exact path="/sign-in" element={<SignIn />} />
        <Route exact path="/calendar" element={<Private Component={Calendar} />} />
        <Route exact path="/schedule-details" element={<Private Component={ScheduleDetails} />} />
        <Route exact path="/zone-prices" element={<Private Component={ZonePrices} />} />
        <Route exact path="/Trucks" element={<Private Component={Trucks} />} />
        <Route exact path="/ThirdPayments" element={<Private Component={ThirdPayments} />} />
        <Route exact path="/NoTypes" element={<Private Component={NoTypes} />} />
        <Route exact path="/AllPatent" element={<Private Component={AllPatent} />} />
        <Route exact path="/ThirdConfigs" element={<Private Component={ThirdConfigs} />} />
        <Route exact path="/dictionary" element={<Private Component={Dictionary} />} />
      </Routes>
    </>
  );
}

export default App;
