import Sidebar from '../partials/Sidebar';

import Header from '../partials/Header';
import React, { useState} from "react";
import axios from "axios";
import { API_URL } from "../utils/Utils";
import MaterialTable from "material-table";
import { useForm } from "react-hook-form";
import Select from 'react-select';
import { Button} from '@material-ui/core';
import xlsExport from 'xlsexport';
import { toast } from 'react-toastify';

function AllPatent({ direction, ...args }) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [dataList, setdataList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [month, setMonth] = useState();
  const {
    register,
    handleSubmit,
    formState: { errors },   
  } = useForm();

  const columns = [
    { field: "patent", title: "Patente", minWidth: 200 },
    { field: "payment", title: "Pago", minWidth: 200 },
    { field: "quantity", title: "Cantidad de pedidos", minWidth: 200 }
  ];

  const downloadExcel = () => {
    try {
    const xls = new xlsExport(dataList.map((item) => ({
      "Proveedor": "CCCS",
      "Tipo de Servicio": "HD",
      "Tipo de despacho": "HD",
      "Patente": item.patent,
      "Cantidad de pedidos": item.quantity,
      "Pago": item.payment
    })), "Total mensual tercero");
    xls.exportToXLS(`total-mensual-tercero.xls`);
    toast.success("Descarga con éxito.");
    } catch (e) {
      toast.error("Seleccione mes para descargar")
    }
  }


  const options = [
    { value: 'CURRENT_MONTH', label: 'Mes actual' },
    { value: 'LAST_MONTH', label: 'Mes anterior' } 
  ];

  const getTrucks = async (search) => {
    try {
      const headers = JSON.parse(localStorage.getItem("headers"));
      const { data } = await axios.get(`${API_URL}/third/payment?month=${search}`, {
        headers: { Authorization: headers },
      });
      setdataList(data);
      toast.success("Mostrando listado con éxito.");
    } catch (e) {
      console.log(e);
    }
  };

    const currentMonth=(mesActual)=>{
      setMonth(mesActual)
    }

    const onSubmit = (data) => {
      getTrucks(month.value,data)
    };

 /*    const handleReset = () => {
      reset()
      setMonth()
    } */
 
  return (
    <div className="flex h-screen overflow-hidden">
    <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
    <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <div className='p-5'>
          {!loading && <Button onClick={downloadExcel} variant='outlined' className="bg-green">Descargar Excel</Button>}
            <div className='p-2'>
              <form className='' onSubmit={handleSubmit(onSubmit)}>
              <div style={{width:'300px'}}>
                <Select
                  value={month}
                  onChange={currentMonth}
                  options={options}
                  />
              </div>
                <button type="submit" disabled={loading} className='w-40 my-5 py-2 bg-blue-900 hover:bg-pink-700 text-white font-semibold rounded-lg'> {!loading ? "Buscar" : "Cargando..."}</button>
              </form>
              <MaterialTable
                columns={columns}
                data={dataList}
                title=""
                options={{
                  paging:true,
                  pageSize:10,
                  pageSizeOptions:[10, 20],
                  }}/>
            </div>
        </div>
    </div>
</div>
  );
}

export default AllPatent;
