import resolveConfig from 'tailwindcss/resolveConfig';

export const tailwindConfig = () => {
  return resolveConfig('./src/css/tailwind.config.js')
}

export const hexToRGB = (h) => {
  let r = 0;
  let g = 0;
  let b = 0;
  if (h.length === 4) {
    r = `0x${h[1]}${h[1]}`;
    g = `0x${h[2]}${h[2]}`;
    b = `0x${h[3]}${h[3]}`;
  } else if (h.length === 7) {
    r = `0x${h[1]}${h[2]}`;
    g = `0x${h[3]}${h[4]}`;
    b = `0x${h[5]}${h[6]}`;
  }
  return `${+r},${+g},${+b}`;
};

export const formatValue = (value) => Intl.NumberFormat('es-CL', {
  style: 'currency',
  currency: 'CLP',
}).format(value);


export const API_URL = /* 'https://ee88-186-107-210-19.sa.ngrok.io/api/v1' */  'https://api.prefactura.cccs.cl/api/v1' ;
// export const API_URL = 'http://localhost:3000/api/v1';