import React, {useState, useEffect} from 'react'
import {useLocation} from "react-router-dom";
import Sidebar from '../partials/Sidebar';
import Header from '../partials/Header';
import axios from 'axios';
import { API_URL, formatValue } from '../utils/Utils';
import MaterialTable from "material-table";
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
const validSizes = ["S", "M", "L"];

function Dictionary() {
    const {handleSubmit, register, formState: {errors}, reset} = useForm();
    const search = useLocation().search;
    const date = new URLSearchParams(search).get('date');
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [isCreateLoading, setIsCreateLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [zonePriceSize, setZonePriceSize] = useState([]);
    const [columns] = useState([
        { title: "ID", field: "id", editable: 'never' },
        { title: "Codigo", field: "code", editable: 'onUpdate'  },
        { title: "Tamaño", field: "size", editable: 'onUpdate'  },
    ]);

    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const apiFetchDictionary = async (withLoading = true) => {
      if (withLoading) 
        setLoading(true);
      const headers = JSON.parse(localStorage.getItem("headers"));
      const {data: sizeItems} = await axios.get(`${API_URL}/size_items_dictionaries`, {headers});
      setZonePriceSize(sizeItems?.size_items_dictionaries);
      if (withLoading) 
        setLoading(false);
    }

    const apiUpdateDictionary = async (id, body) => {
        const headers = JSON.parse(localStorage.getItem("headers"));
        if(!validSizes.includes(body.size_items_dictionary.size)){
            return toast.error("El Tamaño no es valido, solo los valores S, M o L son admitidos.")
        }

        try{
            await axios.put(`${API_URL}/size_items_dictionaries/${id}`, body, {headers});
            await apiFetchDictionary(false);
        }catch(e){
        }
    }

    const apiDeleteItem = async (id) => {
        const headers = JSON.parse(localStorage.getItem("headers"));
        try{
            await axios.delete(`${API_URL}/size_items_dictionaries/${id}`, {headers});
            await apiFetchDictionary(false);
        }catch(e){
        }
    }

    useEffect(() => {
        apiFetchDictionary();
    }, []);

    const createElementAPI = async (data) => {
        const headers = JSON.parse(localStorage.getItem("headers"));
        try{
            const {data: sizeItems} = await axios.post(`${API_URL}/size_items_dictionaries`, data, {headers});
            await apiFetchDictionary(false);
            setIsCreateLoading(false)
            toast.success("Se ha creado el elemento con exito.");
            reset();
        }catch(e){
            toast.error("No se ha creado el elemento, por favor reintente.")
            setIsCreateLoading(false)
            return;
        }
        setOpen(false);
    }

    const onSubmit = (data) => {
        setIsCreateLoading(true);
        if(!validSizes.includes(data.size)){
            setIsCreateLoading(false);
            return toast.error("El Tamaño no es valido, solo los valores S, M o L son admitidos.")
        }
        createElementAPI(data);
    }
    
    return (
        <div className="flex h-screen overflow-hidden">
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                <div className='p-5'>
                    <Button variant="outlined" onClick={handleClickOpen}>
                        Crear codigo
                    </Button>
                    <div className='p-2'>
                    {!loading ? <MaterialTable
                        columns={columns}
                        data={zonePriceSize}
                        title="Codigo - Tamaño"
                        editable={{
                            isDeleteHidden: false,
                            onRowUpdate: (newData, oldData) => apiUpdateDictionary(oldData.id, {size_items_dictionary: {...oldData, code: newData.code, size: newData.size}}),
                            onRowDelete: (oldData) => apiDeleteItem(oldData.id)
                        }}
                        options={{
                            paging:true,
                            pageSize:20,
                            pageSizeOptions:[10, 20, 40, 60, 100],    // rows selection options
                        }}
                    />
                    : <CircularProgress/>}
                    </div>
                </div>
            </div>
            <div>
                <Dialog open={open} onClose={handleClose}>
                    <DialogTitle>Crear elemento</DialogTitle>
                    <DialogContent>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <label class="block mb-2 text-indigo-500">Codigo</label>
                            <input {...register("code", { required: true })} class="w-full p-2 mb-6 text-indigo-700 border-b-2 border-indigo-500 outline-none focus:bg-gray-300" type="text" />
                            
                            <label class="block mb-2 text-indigo-500">Tamaño (S = pequeño, M = mediano, L = grande)</label>
                            <input {...register("size", { required: true })} class="w-full p-2 mb-6 text-indigo-700 border-b-2 border-indigo-500 outline-none focus:bg-gray-300" type="text" />

                            <button disabled={isCreateLoading} className="w-40 bg-indigo-700 hover:bg-pink-700 text-white font-bold py-2 px-4 rounded" type="submit">{isCreateLoading ? "Creando..." : "Crear"}</button>
                        </form>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Cancelar</Button>
                    </DialogActions>
                </Dialog>
            </div>
        </div>
    )
}

export default Dictionary;
