import React, {useState, useEffect} from 'react'
import {useLocation} from "react-router-dom";
import Sidebar from '../partials/Sidebar';
import Header from '../partials/Header';
import axios from 'axios';
import { API_URL, formatValue } from '../utils/Utils';
import MaterialTable from "material-table";
import { Button, CircularProgress } from '@material-ui/core';

function ZonePrices() {
    const search = useLocation().search;
    const date = new URLSearchParams(search).get('date');
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [zonePriceSize, setZonePriceSize] = useState([]);
    const [columns] = useState([
        { title: "ID", field: "id", editable: 'never' },
        { title: "Zona", field: "attributes.zone.name", editable: 'never' },
        { title: "Tamaño", field: "attributes.size", editable: 'never' },
        { title: "Precio", field: "attributes.price", type: 'numeric' },
    ]);

    const apiFetchZonePriceSize = async (withLoading = true) => {
      if (withLoading) 
        setLoading(true);
      const headers = JSON.parse(localStorage.getItem("headers"));
      const {data: zonePriceSizes} = await axios.get(`${API_URL}/zone_price_sizes`, {headers});
      setZonePriceSize(zonePriceSizes?.data);
      if (withLoading) 
        setLoading(false);
    }

    const apiUpdateZonePriceSize = async (id, body) => {
        const headers = JSON.parse(localStorage.getItem("headers"));
        try{
            await axios.put(`${API_URL}/zone_price_sizes/${id}`, body, {headers});
            await apiFetchZonePriceSize(false);
        }catch(e){
        }
    }

    useEffect(() => {
        apiFetchZonePriceSize();
    }, []);
    
    return (
        <div className="flex h-screen overflow-hidden">
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                <div className='p-5'>
                    <div className='p-2'>
                    {!loading ? <MaterialTable
                        columns={columns}
                        data={zonePriceSize}
                        title=""
                        editable={{
                            isDeleteHidden: true,
                            onRowUpdate: (newData, oldData) => apiUpdateZonePriceSize(oldData.id, {zone_price_size: {...oldData, price: newData.attributes.price}})
                        }}
                        options={{
                            paging:true,
                            pageSize:20,
                            pageSizeOptions:[10, 20, 40, 60, 100],
                        }}
                    />
                    : <CircularProgress/>}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ZonePrices;
