import React, { useState, useEffect } from 'react';

import {useForm} from 'react-hook-form';
import axios from 'axios';
import { API_URL } from '../utils/Utils';
import { toast } from 'react-toastify';

function SignIn() {
  const { register, handleSubmit, watch, formState: { errors } } = useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const headers = JSON.parse(localStorage.getItem('headers'));
    if(headers){
      window.location.href = '/';
    }
  }, []);

  const onSubmit = async (data) => {
    setLoading(true);
    try{
      const {headers, data: dataUser} = await axios.post(`${API_URL}/auth/sign_in`, data);
      localStorage.setItem('headers', JSON.stringify(headers));
      localStorage.setItem('user', JSON.stringify(dataUser.data));
      window.location.href = '/';
    } catch(e) {
      toast.error("Error usuario o contraseña incorrecta");
    }
    setLoading(false);
  };

  return (
    <div className="flex h-screen overflow-hidden">
      <div class="w-full max-w-xs m-auto bg-indigo-100 rounded p-5">   
        <header>
          <img class="w-40 mx-auto mb-5" src="https://cccs.cl/wp-content/uploads/2021/09/logo_cccs_transporte_y_logistica.png" />
        </header>   
        <form onSubmit={handleSubmit(onSubmit)}>
            <div>
              <label class="block mb-2 text-indigo-500">Correo electronico</label>
              <input {...register("email", { required: true })} class="w-full p-2 mb-6 text-indigo-700 border-b-2 border-indigo-500 outline-none focus:bg-gray-300" type="text" />
            </div>
            <div>
              <label class="block mb-2 text-indigo-500">Contraseña</label>
              <input {...register("password", { required: true })} class="w-full p-2 mb-6 text-indigo-700 border-b-2 border-indigo-500 outline-none focus:bg-gray-300" type="password" />
            </div>
            <div>          
              <button class="w-full bg-indigo-700 hover:bg-pink-700 text-white font-bold py-2 px-4 mb-6 rounded" type="submit" disabled={loading}>{!loading ? "Ingresar" : "Cargando..."}</button>
            </div>       
        </form>
      </div>
    </div>
  );
}

export default SignIn;