import React, {useState, useEffect} from 'react'
import {useLocation} from "react-router-dom";
import Sidebar from '../partials/Sidebar';
import Header from '../partials/Header';
import axios from 'axios';
import { API_URL, formatValue } from '../utils/Utils';
import MaterialTable from "material-table";
import { Button, CircularProgress } from '@material-ui/core';
import xlsExport from 'xlsexport';
const validSizes = ["S", "M", "L"];

function ScheduleDetails() {
    const search = useLocation().search;
    const date = new URLSearchParams(search).get('date');
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [scheduleData, setScheduleData] = useState([]);
    const [scheduleDataOld, setScheduleDataOld] = useState([]);
    const [togglePatent, setTogglePatent] = useState(false);
    const [columns, setColumns] = useState([
        { title: "SG", field: "attributes.socdo", editable: "onUpdate" },
        { title: "Codigo producto", field: "attributes.code_product_name", editable: "onUpdate" },
        { title: "Producto", field: "attributes.name", editable: "onUpdate" },
        { title: "Tamaño", field: "attributes.size", editable: "onUpdate" },
        { title: "Patente", field: "attributes.truck.patent", editable: "never" },
        { title: "Comuna", field: "attributes.commune.name", editable: "never" },
        { title: "Zona", field: "attributes.zone.name", editable: "never" },
        { title: "Ultima salida", field: "attributes.departure_at", editable: "never" },
        { title: "Precio", field: "attributes.price", render: rowData => rowData.attributes.price, editable: "onUpdate" },
        { title: "Estado", field: "attributes.state", editable: "never" },
        { title: "Subestado", field: "attributes.sub_state", editable: "never" },
        { title: "Piezas", field: "attributes.item_pieces", render: rowData => rowData.attributes.item_pieces.length + 1 || 1, editable: "never" },
    ]);

    
    const apiFetchSchedule = async (withLoading = true) => {
      if(withLoading)
        setLoading(true);
      const headers = JSON.parse(localStorage.getItem("headers"));
      const {data: dataSchedule} = await axios.get(`${API_URL}/items?search=DATE&date=${date}`, {headers});
      setScheduleData(dataSchedule?.data);
      setScheduleDataOld(dataSchedule?.data);
      setLoading(false);
    }

    /* aqui se edita todo de la tabla */

    const apiUpdateScheduleDetail = async (id, body) => {
        const headers = JSON.parse(localStorage.getItem("headers"));
        if(!validSizes.includes(body.item.size)){
            return toast.error("El Tamaño no es valido, solo los valores S, M o L son admitidos.")
        }
        body.item.price = parseInt(body.item.price);

        try{
            await axios.put(`${API_URL}/items/${id}`, body, {headers});
            await apiFetchSchedule(false);
        }catch(e){
        }
    }

    useEffect(() => {
        apiFetchSchedule();
    }, []);

    const downloadExcel = () => {
        const xls = new xlsExport(scheduleData.map((item) => ({
            "Proveedor": "CCCS",
            "Tipo de Servicio": "HD",
            "Tipo de despacho": "HD",
            "SG": item.attributes.socdo,
            "Producto": item.attributes.name,
            "Patente": item.attributes.truck?.patent,
            "Fecha de Compromiso": item.attributes.date_agreed,
            "Fecha de Entrega": item.attributes.arrived_at,
            "Numero de Intentos": item.attributes.attemps,
            "Comuna destino": item.attributes.commune?.name,
            "Radio": item.attributes.commune?.radio,
            "Tamaño": item.attributes.size,
            "KG. Fisico": item.attributes.kg,
            "Alto (cm)": item.attributes.category_item?.height,
            "Largo (cm)": item.attributes.category_item?.depth,
            "Ancho (cm)": item.attributes.category_item?.width,
            "kg. volumetrico": item.attributes.kg_volumetrico,
            "kg. cobrado": item.attributes.higher,
            "Costo Despacho": item.attributes.price,
            "Zona": item.attributes.zone?.name,
            "Estado": item.attributes.state || "",
            "SubEstado": item.attributes.sub_state || "",
            "Producto2": item.attributes.item_pieces[0]?.name,
            "Producto3": item.attributes.item_pieces[1]?.name,
            "Producto4": item.attributes.item_pieces[2]?.name,
            "Producto5": item.attributes.item_pieces[3]?.name,
            "Producto6": item.attributes.item_pieces[4]?.name,
            "Producto7": item.attributes.item_pieces[5]?.name,
            "Producto8": item.attributes.item_pieces[6]?.name,
            "Producto9": item.attributes.item_pieces[7]?.name,
            "Producto10": item.attributes.item_pieces[8]?.name,
        })), "prefactura");
        xls.exportToXLS(`${date}-prefactura.xls`);
    }
    
    const arrayToObject = (arr, key) => {
        return arr.reduce((obj, item) => {
            obj[item[key]] = item
            return obj
        }, {})
    }

    const onClickPatent = () => {
        setTogglePatent((prev) => !prev);
        if(!togglePatent){
            setColumns([
                { title: "Patente", field: "patent" },
                { title: "Cantidad", field: "qty" },
                { title: "Monto", field: "attributes.price", render: (row) => formatValue(row.price)},
            ]);
            const result = [];
            scheduleData.reduce(function(acc, value) {
                if (!acc[value.attributes.truck.patent]) {
                    acc[value.attributes.truck.patent] = { patent: value.attributes.truck.patent, qty: 1, price: 0 };
                  result.push(acc[value.attributes.truck.patent])
                }
                acc[value.attributes.truck.patent].qty += value.attributes.item_pieces.length;
                acc[value.attributes.truck.patent].price += value.attributes.price;
                return acc;
            }, {})
            setScheduleData(result);
        }else{
            setColumns([
                { title: "SG", field: "attributes.socdo", editable: "onUpdate" },
                { title: "Codigo producto", field: "attributes.code_product_name", editable: "onUpdate" },
                { title: "Producto", field: "attributes.name", editable: "onUpdate" },
                { title: "Tamaño", field: "attributes.size", editable: "onUpdate" },
                { title: "Patente", field: "attributes.truck.patent" },
                { title: "Comuna", field: "attributes.commune.name" },
                { title: "Zona", field: "attributes.zone.name" },
                { title: "Ultima salida", field: "attributes.departure_at" },
                { title: "Precio", field: "attributes.price", render: rowData => rowData.price, editable: "onUpdate" },
                { title: "Piezas", field: "attributes.item_pieces", render: rowData => rowData.attributes.item_pieces.length + 1 || 1 },
            ])
            setScheduleData(scheduleDataOld);
        }
    }
    
    return (
        <div className="flex h-screen overflow-hidden">
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                <div className='p-5'>
                    {!loading && <Button onClick={downloadExcel} variant='outlined' className="bg-green">Descargar Excel</Button>}
                    {!loading && <Button onClick={onClickPatent} variant='outlined'>Ver {!togglePatent ? "patentes" : "detalle de productos"}</Button>}
                    <div className='p-2'>
                    {!loading ? <MaterialTable
                        columns={columns}
                        data={scheduleData}
                        title={`Detalle de ${date}`}
                        editable={{
                            isDeleteHidden: false,
                            onRowUpdate: (newData, oldData) => apiUpdateScheduleDetail(oldData.id, {item: {...newData}}),
                        }}
                        options={{
                            paging:true,
                            pageSize:10,
                            pageSizeOptions:[10, 20,40,60,100, scheduleData?.length],    // rows selection options
                        }}
                    />
                    : <CircularProgress/>}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ScheduleDetails;
