import React, { useState, useEffect, useCallback, useRef } from 'react';
import Sidebar from '../partials/Sidebar';
import Header from '../partials/Header';
import axios from 'axios';
import { API_URL, formatValue } from '../utils/Utils';
import CalendarTUI from '@toast-ui/react-calendar';
import 'tui-calendar/dist/tui-calendar.css';
import {Button} from "@material-ui/core";
import 'tui-date-picker/dist/tui-date-picker.css';
import 'tui-time-picker/dist/tui-time-picker.css';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import Select from 'react-select';

function Calendar() {
  const [dataPrice, setDataPrice] = useState();
  const {handleSubmit, register, reset } = useForm();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState(null);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [calendarData, setCalendarData] = useState([]);
  const [calendarDataAPI, setCalendarDataAPI] = useState([]);
  const [calendarDataPrice, setCalendarDataPrice] = useState([]);
  const [calendarDataPriceAPI, setCalendarDataPriceAPI] = useState([]);
  const [currentDate, setCurrentDate] = useState("");
  const [month, setMonth] = useState();
  const calendarRef = useRef(null);

  
  const apiFetchCalendar = async () => {
    const headers = JSON.parse(localStorage.getItem("headers"));
    const {data: dataCalendar} = await axios.get(`${API_URL}/items?search=OVERVIEW_CALENDAR_ITEMS`, {headers});
    setCalendarDataAPI(dataCalendar);
  }

  const apiFetchCalendarPrice = async () => {
    const headers = JSON.parse(localStorage.getItem("headers"));
    const {data: dataCalendarPrice} = await axios.get(`${API_URL}/items?search=OVERVIEW_CALENDAR`, {headers});
    setCalendarDataPriceAPI(dataCalendarPrice);
  }

  const apiPrice = async () => {
    const headers = JSON.parse(localStorage.getItem("headers"));
    const {data: dataPriceValue} = await axios.get(`${API_URL}/show_total`, {headers});
    setDataPrice(dataPriceValue);
  }

  const apiEmail = async (search) => {
    toast.info("Enviando reporte, espere porfavor mientras se carga el archivo...")
    setLoading(true);
    try{
    const headers = JSON.parse(localStorage.getItem("headers"));
    const res = await axios.get(`${API_URL}/report_excel?month=${search}&email=${email}`, {headers});
    }catch(e){
      toast.error("No se ha enviado el reporte")
      setEmail(false)
      return;
    }
      setLoading(false);
      reset();
  }
  /* onInit angular */
  useEffect(() => { 
    apiFetchCalendar();
    apiFetchCalendarPrice();
    apiPrice();
  }, []);

  const calendars = [
    {
      id: "1",
      color: "#ffffff",
      bgColor: "#F33B37",
      borderColor: "#0f0f0f0"
    },
    {
      id: "2",
      color: "#ffffff",
      bgColor: "#00a9ff",
      borderColor: "#0f0f0f0"
    }
  ];

  const options = [
    { value: 'CURRENT', label: 'Mes actual' },
    { value: 'LAST', label: 'Mes anterior' }
  ];

  const currentMonth=(mesActual)=>{
    setMonth(mesActual)
  };

  const onSubmit = () => {
    apiEmail(month.value,email)
  };

  useEffect(() => {
    if(calendarDataAPI){
      setCalendarData(calendarDataAPI.map((data) => ({
        start: data.start, 
        end: data.end, 
        body: data.body, 
        calendarId: "2",
        category: "allday",
        isVisible: true,
        title: data.body,
        id: "1"
      })));
    }
  }, [calendarDataAPI]);


  useEffect(() => {
    if(calendarDataPriceAPI){
      setCalendarDataPrice(calendarDataPriceAPI.map((data) => ({
        start: data.start, 
        end: data.end, 
        body: formatValue(data.body), 
        calendarId: "1",
        category: "allday",
        isVisible: true,
        title: formatValue(data.body),
        id: "1"
      })));
    }
  }, [calendarDataPriceAPI])

  const calendarSchedules = [...calendarData, ...calendarDataPrice];
  const onClickSchedule = useCallback((e) => {
    const date = e.schedule.start;
    const dateQuery =   date.getFullYear() + '/' + ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '/' +  ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate()));
    location.href= `/schedule-details?date=${dateQuery}`;
  }, []);

  const prevMonth = () => {
    calendarRef.current.calendarInst.prev();
    setCurrentDate(`${calendarRef?.current?.calendarInst?.getDate()?.getFullYear()}/${calendarRef?.current?.calendarInst?.getDate()?.getMonth()+1}`);
  };

  const nextMonth = () => {
    calendarRef.current.calendarInst.next();
    setCurrentDate(`${calendarRef?.current?.calendarInst?.getDate()?.getFullYear()}/${calendarRef?.current?.calendarInst?.getDate()?.getMonth()+1}`);
  };

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <div>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            <div className='p-2 gap-2 flex items-center'>
              <Button color='primary' variant="outlined" onClick={prevMonth}>Anterior</Button>
              <Button color='primary' variant="outlined" onClick={nextMonth}>Proximo</Button>
              <h1 className='font-mono ml-10 text-xl'>{currentDate || `${new Date().getFullYear()}/${new Date().getMonth()+1}`}</h1>
              <h1 className='font-mono mr-10 ml-10 text-xl'>Monto total: {"$"+new Intl.NumberFormat().format(dataPrice)}</h1>
              <form onSubmit={handleSubmit(onSubmit)}>
                  <Select
                   value={month}
                   onChange={currentMonth}
                   options={options} />
                  <input placeholder='Ingrese Email' {...register("email")} required className="mt-5 w-40 ml-30 mr-3 text-indigo-700 border-b-2 border-indigo-500 outline-none focus:bg-gray-300" type="email" name="email" onChange={handleChange} autoComplete='off'></input>
                  <button className="w-50 bg-indigo-700 hover:bg-pink-700 text-white font-bold py-2 px-4 mb-6 rounded" type="submit" disabled={loading}>{!loading ? "Enviar" : "Enviando..."}</button>
                </form>
            </div>
            <CalendarTUI
              ref={calendarRef}
              height="1000px"
              view="month"
              lang='ES'
              schedules={calendarSchedules}
              calendars={calendars}
              disableClick
              ScheduleView
              usageStatistics={false}
              onClickSchedule={onClickSchedule}
              isReadOnly
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Calendar;
