import React, { useState, useEffect, useRef } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Icon } from "@iconify/react";

function Sidebar({
  sidebarOpen,
  setSidebarOpen
}) {

  const location = useLocation();
  const { pathname } = location;

  const trigger = useRef(null);
  const sidebar = useRef(null);

  const storedSidebarExpanded = localStorage.getItem('sidebar-expanded');
  const [sidebarExpanded, setSidebarExpanded] = useState(storedSidebarExpanded === null ? false : storedSidebarExpanded === 'true');

  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!sidebar.current || !trigger.current) return;
      if (!sidebarOpen || sidebar.current.contains(target) || trigger.current.contains(target)) return;
      setSidebarOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!sidebarOpen || keyCode !== 27) return;
      setSidebarOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  useEffect(() => {
    localStorage.setItem('sidebar-expanded', sidebarExpanded);
    if (sidebarExpanded) {
      document.querySelector('body').classList.add('sidebar-expanded');
    } else {
      document.querySelector('body').classList.remove('sidebar-expanded');
    }
  }, [sidebarExpanded]);

  return (
    <div>
      <div className={`fixed inset-0 bg-slate-900 bg-opacity-30 z-40 lg:hidden lg:z-auto transition-opacity duration-200 ${sidebarOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'}`} aria-hidden="true"></div>
      <div
        id="sidebar"
        ref={sidebar}
        className={`flex flex-col absolute z-40 left-0 top-0 lg:static lg:left-auto lg:top-auto lg:translate-x-0 transform h-screen overflow-y-scroll lg:overflow-y-auto no-scrollbar w-64 lg:w-20 lg:sidebar-expanded:!w-64 2xl:!w-64 shrink-0 bg-slate-800 p-4 transition-all duration-200 ease-in-out ${sidebarOpen ? 'translate-x-0' : '-translate-x-64'}`}
      >
        <div className="flex justify-between mb-10 pr-3 sm:px-2">
          <button
            ref={trigger}
            className="lg:hidden text-slate-500 hover:text-slate-400"
            onClick={() => setSidebarOpen(!sidebarOpen)}
            aria-controls="sidebar"
            aria-expanded={sidebarOpen}
          >
            <span className="sr-only">Close sidebar</span>
            <svg className="w-6 h-6 fill-current" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="M10.7 18.7l1.4-1.4L7.8 13H20v-2H7.8l4.3-4.3-1.4-1.4L4 12z" />
            </svg>
          </button>
          <NavLink end to="/" className="block">
            <img src="https://cccs.cl/wp-content/uploads/2021/09/logo_cccs_transporte_y_logistica.png"/>
          </NavLink>
        </div>
        <div className="space-y-8">
          <div>
            <h3 className="text-xs uppercase text-slate-500 font-semibold pl-3">
              <span className="hidden lg:block lg:sidebar-expanded:hidden 2xl:hidden text-center w-6" aria-hidden="true">•••</span>
              <span className="lg:hidden lg:sidebar-expanded:block 2xl:block">Paginas</span>
            </h3>
            <ul className="mt-3">
              <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${pathname === '/' && 'bg-slate-900'}`}>
                <NavLink end to="/" className={`block text-slate-200 hover:text-white truncate transition duration-150 ${pathname === '/' && 'hover:text-slate-200'}`}>
                  <div className="flex items-center">
                  <Icon 
                    icon="mdi:monitor-dashboard"
                    style={{ fontSize: "25px" }}
                    className="shrink-0 h-6 w-6"
                    viewBox="0 0 24 24" />
                    <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">Dashboard</span>
                  </div>
                </NavLink>
              </li>
              
              <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${pathname.includes('calendar') && 'bg-slate-900'}`}>
                <NavLink end to="/calendar" className={`block text-slate-200 hover:text-white truncate transition duration-150 ${pathname.includes('calendar') && 'hover:text-slate-200'}`}>
                  <div className="flex items-center">
                  <Icon 
                    icon="material-symbols:calendar-month-outline-rounded" 
                    style={{ fontSize: "25px" }}
                    className="shrink-0 h-6 w-6"
                    viewBox="0 0 24 24" />
                    <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">Calendario</span>
                  </div>
                </NavLink>
              </li>
              <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${pathname.includes('zone-prices') && 'bg-slate-900'}`}>
                <NavLink end to="/zone-prices" className={`block text-slate-200 hover:text-white truncate transition duration-150 ${pathname.includes('calendar') && 'hover:text-slate-200'}`}>
                  <div className="flex items-center">
                  <Icon 
                    icon="ic:outline-price-change"
                    style={{ fontSize: "25px" }}
                    className="shrink-0 h-6 w-6"
                    viewBox="0 0 24 24" />
                    <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">Precios por zona</span>
                  </div>
                </NavLink>
              </li>
               <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${pathname.includes('Trucks') && 'bg-slate-900'}`}>
                <NavLink end to="/Trucks" className={`block text-slate-200 hover:text-white truncate transition duration-150`}>
                  <div className="flex items-center">
                  <Icon
                      icon="mdi:truck-outline"
                      style={{ fontSize: "25px" }}
                      className="shrink-0 h-6 w-6"
                      viewBox="0 0 24 24"
                    />
                    <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">Camiones</span>
                  </div>
                </NavLink>
              </li>
              
                <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${pathname.includes('ThirdPayments') && 'bg-slate-900'}`}>
                <NavLink end to="/ThirdPayments" className={`block text-slate-200 hover:text-white truncate transition duration-150 ${pathname.includes('calendar') && 'hover:text-slate-200'}`}>
                  <div className="flex items-center">
                  <Icon 
                    icon="mdi:account-payment-outline"
                    style={{ fontSize: "25px" }}
                    className="shrink-0 h-6 w-6"
                    viewBox="0 0 24 24" />
                    <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">Pago a terceros</span>
                  </div>
                </NavLink>
              </li>
               
               <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${pathname.includes('NoTypes') && 'bg-slate-900'}`}>
                <NavLink end to="/NoTypes" className={`block text-slate-200 hover:text-white truncate transition duration-150 ${pathname.includes('calendar') && 'hover:text-slate-200'}`}>
                  <div className="flex items-center">
                  <Icon 
                     icon="mdi:truck-remove"
                    style={{ fontSize: "25px" }}
                    className="shrink-0 h-6 w-6"
                    viewBox="0 0 24 24" />
                    <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">Camiones sin tipo</span>
                  </div>
                </NavLink>
              </li>
            
               <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${pathname.includes('AllPatent') && 'bg-slate-900'}`}>
                <NavLink end to="/AllPatent" className={`block text-slate-200 hover:text-white truncate transition duration-150 ${pathname.includes('calendar') && 'hover:text-slate-200'}`}>
                  <div className="flex items-center">
                  <Icon 
                     icon="material-symbols:clear-all-rounded"
                    style={{ fontSize: "25px" }}
                    className="shrink-0 h-6 w-6"
                    viewBox="0 0 24 24" />
                    <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">Total Mensual tercero</span>
                  </div>
                </NavLink>
              </li>
             
               <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${pathname.includes('ThirdConfigs') && 'bg-slate-900'}`}>
                <NavLink end to="/ThirdConfigs" className={`block text-slate-200 hover:text-white truncate transition duration-150 ${pathname.includes('calendar') && 'hover:text-slate-200'}`}>
                  <div className="flex items-center">
                  <Icon 
                    icon="grommet-icons:configure"
                    style={{ fontSize: "25px" }}
                    className="shrink-0 h-6 w-6"
                    viewBox="0 0 24 24" />
                    <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">Configuracion terceros</span>
                  </div>
                </NavLink>
              </li> 
            </ul>
          </div>
        </div>
        <div className="pt-3 hidden lg:inline-flex 2xl:hidden justify-end mt-auto">
          <div className="px-3 py-2">
            <button onClick={() => setSidebarExpanded(!sidebarExpanded)}>
              <span className="sr-only">Expand / collapse sidebar</span>
              <Icon 
                icon="mdi:monitor-dashboard"
                style={{ fontSize: "25px", color:"white"}}
                className="shrink-0 h-6 w-6"
                viewBox="0 0 24 24" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
