import Sidebar from '../partials/Sidebar';
import Header from '../partials/Header';
import React, { useState, useEffect } from "react";
import axios from "axios";
import { API_URL } from "../utils/Utils";
import MaterialTable from "material-table";
import { toast } from 'react-toastify';

function NoTypes() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [dataList, setdataList] = useState([]);
  const [month, setMonth] = useState();

  const columns = [
    { field: "id", title: "ID", minWidth: 200, editable: "never" },
    { field: "attributes.driver_name", title: "Nombre del conductor", minWidth: 200, editable: "never" },
    { field: "attributes.patent", title: "Patente", minWidth: 200 },
    { field: "attributes.type_truck", title: "Tipo de camion", minWidth: 200 },
    { field: "attributes.updated_at", title: "Fecha de actualizacion", minWidth: 200, editable: "never" }
  ];

  const getTrucks = async () => {
  try {
    const headers = JSON.parse(localStorage.getItem("headers"));
    const {
      data: { data },
    } = await axios.get(`${API_URL}/truck/no_types`, {
      headers: { Authorization: headers },
    });
    const truck =  data.map(item => item)
    setdataList(truck);
  } catch (e) {
    toast.error("No hay datos disponibles")
    console.log(e);
  }
};

  const apiUpdateTrucks = async (id, body) => {
    const headers = JSON.parse(localStorage.getItem("headers"));
    try{
        await axios.put(`${API_URL}/trucks/${id}`, body, {headers});
        await getTrucks();
        toast.success("Se ha editado el elemento con exito.");
    }catch(e){
    }
  }

  useEffect(() => {
    getTrucks();
  }, []);

  return (
    <div className="flex h-screen overflow-hidden">
    <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
    <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <div className='p-5'>
            <div className='p-2'>
            <MaterialTable
                columns={columns}
                data={dataList}
                title=""
                editable={{
                  isDeleteHidden: true,
                  onRowUpdate: (newData, oldData) => {
                  return apiUpdateTrucks(oldData.id, {truck: {patent: newData.attributes.patent, type_truck: newData.attributes.type_truck}})}
                  }}
                options={{
                    paging:true,
                    pageSize:10,
                    pageSizeOptions:[10, 20],
                }}
            />
            </div>
        </div>
    </div>
</div>
  );
}

export default NoTypes;