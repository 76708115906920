import React, { useState} from "react";
import axios from "axios";
import {useLocation} from "react-router-dom";
import { API_URL } from "../utils/Utils";
import MaterialTable from "material-table";
import { useForm } from "react-hook-form";
import Select from 'react-select';
import Sidebar from '../partials/Sidebar';
import Header from '../partials/Header';
import { Button} from '@material-ui/core';
import xlsExport from 'xlsexport';
import { toast } from 'react-toastify';

function ThirdPayments() {
  const search = useLocation().search;
  const date = new URLSearchParams(search).get('date');
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [dataList, setdataList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [month, setMonth] = useState();
/*   const [patent, setPatent] = useState(); */
  const {
    register,
    handleSubmit,
  } = useForm();

  const columns = [
    { title: "ID", field: "id", editable: 'never', minWidth: 200, editable: "never" },
    { title: "Pago", field: "attributes.payment", minWidth: 200 },
    { title: "Patente", field: "attributes.patent", minWidth: 200 },
    { title: "Cantidad", field: "attributes.quantity", minWidth: 200 },
    { title: "Zona", field: "attributes.zone", minWidth: 200 },
    { title: "Comuna", field: "attributes.commune", minWidth: 200 },
    { title: "Fecha", field: "attributes.date", editable: 'never', minWidth: 200, editable: "never" },
  ];
    
    const options = [
      { value: 'CURRENT_MONTH', label: 'Mes actual' },
      { value: 'LAST_MONTH', label: 'Mes anterior' }
    ];
  
    const getThirdPayments = async (search) => { /* agregar aqui patent */
      try {
        const headers = JSON.parse(localStorage.getItem("headers"));
        const {
          data: { data },
        } = await axios.get(`${API_URL}/third_payments?search=${search}`, { /* &patent=${patent} "en caso de que se necesite filtro de patente" */
          headers: { Authorization: headers },
        });
        const truck =  data.map(item => item)
        setdataList(truck);
        toast.success("Mostrando listado con éxito.");
      } catch (e) {
        console.log(e);
      }
    };
  
  

    const apiUpdateThirdPayment = async (id, body) => {
      const headers = JSON.parse(localStorage.getItem("headers"));
      try{
          await axios.put(`${API_URL}/third_payments/${id}`, body, {headers});
          await getThirdPayments();
          toast.success("Se ha editado el elemento con exito.");
      }catch(e){
        toast.error("No se ha podido editar el elemento")
      }
    }
  
      const currentMonth=(mesActual)=>{
        setMonth(mesActual)
      }

      const downloadExcel = () => {
        try {
        const xls = new xlsExport(dataList.map((item) => ({
          "Proveedor": "CCCS",
          "Tipo de Servicio": "HD",
          "Tipo de despacho": "HD",
          "Patente": item.attributes.patent,
          "Cantidad de pedidos": item.attributes.quantity,
          "Pago": item.attributes.payment,
          "Zona": item.attributes.zone,
          "Comuna": item.attributes.commune,
          "Fecha": item.attributes.date
        })), "Pago a terceros");
        xls.exportToXLS(`pago-a-terceros.xls`);
        toast.success("Descarga con éxito.");
      } catch (e) {
        toast.error("Seleccione mes para descargar")
      }
      }

   /*    const currentPatent=(event)=>{
        setPatent(event.target.value);
      } */
  
      const onSubmit = (data) => {
        getThirdPayments(month.value,data)
      };
  
  /*     const handleReset = () => {
        reset()
        setMonth()
      } */
      
 return (
        <div className="flex h-screen overflow-hidden">
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
              <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                <div className='p-5'>
                {!loading && <Button onClick={downloadExcel} variant='outlined' className="bg-green">Descargar Excel</Button>}
                  <div className='p-2'>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div style={{width:'300px'}}>
                       <Select
                          value={month}
                          onChange={currentMonth}
                          options={options}
                        />
                      </div>
                     {/*    <div className=''>
                          <input {...register("patente")} onChange={currentPatent} required placeholder="Patente" className='p-2 rounded-lg bg-white mt-2 focus:border-blue-500 focus:outline-none' type="text"/>
                        </div> */}
                          <button type="submit" disabled={loading} className='w-40 my-5 py-2 bg-blue-900 hover:bg-pink-700 text-white font-semibold rounded-lg'> {!loading ? "Buscar" : "Cargando..."}</button>
                      </form>
                        <MaterialTable
                          columns={columns}
                          data={dataList}
                          title=""
                          editable={{
                          isDeleteHidden: true,
                          onRowUpdate: (newData, oldData) => {
                          return apiUpdateThirdPayment(oldData.id, {third_payment: {patent: newData.attributes.patent, payment: newData.attributes.payment, quantity: newData.attributes.quantity}})}
              	          }}
                          options={{
                          paging:true,
                          pageSize:10,
                          pageSizeOptions:[10, 20],
                          }}
                        />
                  </div>
                </div>
            </div>
        </div>
    )
}

export default ThirdPayments;


