import React, { useState, useEffect } from 'react';

import Sidebar from '../partials/Sidebar';
import Header from '../partials/Header';
import WelcomeBanner from '../partials/dashboard/WelcomeBanner';
import DashboardCard05 from '../partials/dashboard/DashboardCard05';
import axios from 'axios';
import { API_URL } from '../utils/Utils';

function Dashboard() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [chartPriceData, setChartPriceData] = useState([]);
  const [chartItemData, setChartItemData] = useState([]);
  const [zoneData, setZoneData] = useState([]);
  
  const apiFetchPrice = async () => {
    const headers = JSON.parse(localStorage.getItem("headers"));
    const {data: dataChartPrice} = await axios.get(`${API_URL}/items?search=OVERVIEW_CHART`, {headers});
    setChartPriceData(dataChartPrice);
  }

  const apiFetchItem = async () => {
    const headers = JSON.parse(localStorage.getItem("headers"));
    const {data: dataChartItem} = await axios.get(`${API_URL}/items?search=OVERVIEW_CHART_ITEMS`, {headers});
    setChartItemData(dataChartItem);
  }

  const apiFetchChartZone = async () => {
    const headers = JSON.parse(localStorage.getItem("headers"));
    const {data: zoneChartData} = await axios.get(`${API_URL}/items?search=CHART_ZONES`, {headers});
    setZoneData(zoneChartData);
  }

  useEffect(() => {
    apiFetchPrice();
    apiFetchItem();
    apiFetchChartZone();
  }, []);

  return (
    <div className="flex h-screen overflow-hidden">
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            <WelcomeBanner />
            <div className="grid grid-cols-12 gap-6">
              <DashboardCard05 title='Cobros' data={chartPriceData} isMoney={true}/>
              <DashboardCard05 title='Productos' data={chartItemData}/>
              <DashboardCard05 title='Zonas' data={zoneData} lines={false}/>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default Dashboard;